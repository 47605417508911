import "./index.scss";

import { useState, useMemo, useCallback, type ComponentProps } from "react";
import { FormattedMessage, defineMessages, useIntl } from "react-intl";
import { useNavigate, useSearchParams, Outlet, useMatch } from "react-router-dom";

import { isHybridTransactionType } from "common/mortgage/transactions/utils";
import KbaAlertBanner from "common/banners/kba_alert_banner";
import { CLONE_TRANSACTION } from "constants/feature_gates";
import { IdentityIssueAlerts } from "common/dashboard/identity_issues";
import { useCloneTransactionAction } from "common/transactions/actions/clone";
import Dashboard, {
  DashboardContent,
  DashboardHeader,
  DashboardBody,
  DashboardTitle,
  DashboardActionBar,
  DashboardFilters,
  DashboardBanner,
} from "common/dashboard";
import { BulkActionBar } from "common/core/bulk_actions";
import {
  BulkActionClearButton,
  BulkActionSeparator,
  useBulkActions,
} from "common/core/bulk_actions/common";
import {
  BulkTransactionDeleteModal,
  useBulkDelete,
  BulkActionDeleteButton,
} from "common/dashboard/bulk_actions/transaction_delete";
import {
  BulkActionArchiveButton,
  BulkActionUnarchiveButton,
  useBulkArchive,
} from "common/dashboard/bulk_actions/transaction_archive";
import {
  StatusFilter,
  TransactionTypeFilter,
  OrderProgressFilter,
  useDateConstraint,
  TransactionSectionNamespace,
  ClearAllButton,
  getActiveFiltersCount,
  ClosingDateFilterV2,
  useToggleSetWithCallback,
  TransactionSubsectionNamespace,
  type ClosingDateFilter,
} from "common/dashboard/filter_dropdown/common";
import { useDebouncedQuery, useFilter } from "common/dashboard/filter";
import {
  mortgageDashboardDeserializer,
  mortgageDashboardSerializer,
} from "common/mortgage/transactions/dashboard/filter";
import { BulkTable } from "common/core/table";
import TablePagination, { usePagination } from "common/core/table/pagination";
import TableSearch from "common/core/table/search";
import LoadingIndicator from "common/core/loading_indicator";
import PlaceOrderOnboardingContainer from "title_portal/account/place_order_onboarding";
import {
  MortgageTransactionType,
  OrganizationTransactionStatus,
  OrganizationTransactionDetailedStatus,
  UserAction,
  Feature,
  AlertName,
  OrganizationTransactionVariant,
  TransactionScope,
  type OrganizationTransactionLabels,
  type OrganizationTransactionColumn,
  type SortDirection,
} from "graphql_globals";
import { useActiveOrganization } from "common/account/active_organization";
import { useNewSetupPageAnalytics } from "common/mortgage/transactions/setup";
import { useQuery, useMutation } from "util/graphql";
import {
  transactionEditRoute,
  transactionDetailsRouteV2,
  SETTINGS_PATH,
  TRANSACTION_PATH,
} from "util/routes";
import { segmentTrack } from "util/segment";
import {
  TransactionTypeColumn,
  FileNumberColumn,
  PrimaryBorrowerColumn,
  USStateColumn,
  AddressColumn,
  TitleUnderwriterColumn,
  ClosingDateColumn,
  RiskLevelColumn,
  StatusColumn,
  LenderColumn,
  LoanNumberColumn,
  OrderProgressColumn,
  OrderProgressLastUpdatedColumn,
  IdentityIssueColumn,
  OrganizationNameColumn,
  KebabMenuColumn,
  type KebabMenuItem,
} from "common/dashboard/columns";
import { NewRealEstateTransactionMenu } from "common/dashboard/menus/new_transaction/real_estate";
import { SEGMENT_EVENTS } from "constants/analytics";
import Apps from "constants/applications";
import { useId } from "util/html";
import { usePermissions } from "common/core/current_user_role";
import {
  isRiskScoresTrialActive,
  useCloneTransaction,
  useProofDefendUpsell,
  useSignTransactionsEnabled,
} from "util/feature_detection";
import { useFeatureFlag } from "common/feature_gating";

import EmptyResults from "./empty_results";
import DeleteOrganizationTransactionsMutation from "./delete_organization_transactions.mutation.graphql";
import TitleDashboardGraph, {
  type TitleDashboard_viewer as Viewer,
  type TitleDashboard_node_Organization as ActiveOrganization,
} from "./index.query.graphql";
import TitleTransactionsGraph, {
  type TitleTransactions_node_Organization_transactions_edges_node as OrganizationTransaction,
} from "./transactions.query.graphql";
import TransactionsSidebar from "./transactions_sidebar";
import { getSubTab } from "./subtab";

const PAGE_SIZE = 50;

const MESSAGES = defineMessages({
  searchPlaceholder: {
    id: "74022eb2-0acd-4dfa-8c6c-35dc48191df3",
    defaultMessage: "Search by signer, loan #, or transaction ID",
  },
  searchLabel: {
    id: "b0e422e9-fdc8-4db4-8ec2-c38ef3869b14",
    defaultMessage: "Search for transactions",
  },
  caption: {
    id: "172cb55b-932b-40f9-9ca6-230705a4d797",
    defaultMessage: "Transactions",
  },
});

type Props = {
  viewer: Viewer;
  activeOrganization: ActiveOrganization;
};

const getFilterVariables = ({
  pageIndex,
  activeOrganizationId,
  selectedTransactionTypes,
  startDate,
  endDate,
  query,
  selectedStatuses,
  section,
  orderProgress,
  subSection,
  sortBy,
}: {
  pageIndex: number;
  activeOrganizationId: string;
  selectedTransactionTypes: Set<MortgageTransactionType>;
  startDate: string | null;
  endDate: string | null;
  query: string | null;
  selectedStatuses: Set<OrganizationTransactionDetailedStatus>;
  section: TransactionSectionNamespace;
  subSection: TransactionSubsectionNamespace | null;
  orderProgress: Set<OrganizationTransactionLabels>;
  sortBy?: { column: OrganizationTransactionColumn; direction: SortDirection };
}) => {
  const commonVariables = {
    offset: PAGE_SIZE * pageIndex,
    organizationId: activeOrganizationId,
    transactionTypes: Array.from(selectedTransactionTypes.values()),
    closingDateStart: startDate,
    closingDateEnd: endDate,
    archived: section === TransactionSectionNamespace.ARCHIVED,
    riskyTransactionsOnly: subSection === TransactionSubsectionNamespace.IDENTITY_RISK,
    duplicatedTransactionsOnly: section === TransactionSectionNamespace.DUPLICATED,
    alerts: subSection === TransactionSubsectionNamespace.KBA_ISSUES ? [AlertName.KBA_FAILURE] : [],
    sortBy: sortBy ? [sortBy] : [],
  };
  if (section === TransactionSectionNamespace.OPEN_ORDER) {
    return {
      ...commonVariables,
      query,
      detailedStatuses: [OrganizationTransactionDetailedStatus.OPEN_ORDER],
      labels: Array.from(orderProgress.values()),
    };
  }
  if (subSection === TransactionSubsectionNamespace.CREATED_BY_ME) {
    return {
      ...commonVariables,
      query,
      scope: TransactionScope.created_by_me,
      detailedStatuses: [],
    };
  }

  return {
    ...commonVariables,
    query,
    detailedStatuses: Array.from(selectedStatuses.values()),
  };
};

const getRelatedProgressStatuses = (
  showPlaceOrderUI: boolean,
  currentUserIsClosingOps: boolean,
) => [
  OrganizationTransactionDetailedStatus.SENT_TO_SIGNER,
  OrganizationTransactionDetailedStatus.ACTIVE,
  OrganizationTransactionDetailedStatus.VIEWED,
  OrganizationTransactionDetailedStatus.PARTIALLY_COMPLETE,
  OrganizationTransactionDetailedStatus.ATTEMPTED,
  OrganizationTransactionDetailedStatus.CONVERTED_TO_WET_SIGN,
  ...(currentUserIsClosingOps ? [OrganizationTransactionDetailedStatus.OPEN_ORDER] : []),
  ...(showPlaceOrderUI ? [OrganizationTransactionDetailedStatus.ORDER_PLACED] : []),
  OrganizationTransactionDetailedStatus.AWAITING_PAYMENT,
  OrganizationTransactionDetailedStatus.ON_HOLD,
];

const draftStatuses = [
  OrganizationTransactionDetailedStatus.DRAFT,
  OrganizationTransactionDetailedStatus.RECALLED,
];

const actionNeededStatuses = [
  OrganizationTransactionDetailedStatus.REVIEW_NOW,
  OrganizationTransactionDetailedStatus.READY_FOR_REVIEW,
];

function TitleTransactionsV2({ viewer, activeOrganization }: Props) {
  const intl = useIntl();
  const navigate = useNavigate();
  const { hasPermissionFor } = usePermissions();
  const transactionsActive = Boolean(
    useMatch({
      path: TRANSACTION_PATH,
      end: false,
    }),
  );
  const showPlaceOrderUI = Boolean(
    activeOrganization.placeAnOrderEnabled && !hasPermissionFor("manageOpenOrders"),
  );
  const proofDefendUpsell = useProofDefendUpsell(activeOrganization);
  const signTransactionsEnabled = useSignTransactionsEnabled();
  const canUseCloneTransaction = useCloneTransaction();
  const cloneTransactionEnabled = useFeatureFlag(CLONE_TRANSACTION);
  const { action: cloneTransaction, isLoading: cloneTransactionLoading } =
    useCloneTransactionAction();
  const [rawQueryArgs] = useSearchParams();

  const organizationHasDuplication = activeOrganization.featureList.includes(
    Feature.DUPLICATE_TRANSACTIONS,
  );
  const showDuplicateUI = hasPermissionFor("manageOpenOrders") && organizationHasDuplication;

  const { handleChange, deserializedArgs } = useFilter(
    mortgageDashboardDeserializer,
    mortgageDashboardSerializer,
  );
  const {
    section,
    subSection,
    query,
    page: pageIndex,
    detailedStatuses: selectedStatuses,
    orderProgress: selectedOrderProgressTypes,
    transactionTypes: selectedTransactionTypes,
    dateConstraint: selectedDateConstraint,
    sortColumn,
    sortDirection,
  } = deserializedArgs;

  const { textFilterValue, handleTextFilterChange } = useDebouncedQuery(handleChange, query, true);

  const setPageIndex = useCallback(
    (page: number) => {
      handleChange({ page });
    },
    [handleChange],
  );

  // everything below here can just read deserializedArgs and has no idea about query args
  const inProgressStatuses = useMemo(
    () =>
      getRelatedProgressStatuses(
        (activeOrganization.placeAnOrderEnabled ||
          activeOrganization.perTransactionPlaceAnOrderEnabled) &&
          !hasPermissionFor("manageOpenOrders"),
        hasPermissionFor("manageOpenOrders"),
      ),
    [],
  );

  const completeStatuses = useMemo(
    () => [
      OrganizationTransactionDetailedStatus.COMPLETE,
      OrganizationTransactionDetailedStatus.ESIGN_COMPLETE,
      OrganizationTransactionDetailedStatus.WET_SIGN_COMPLETE,
      OrganizationTransactionDetailedStatus.COMPLETE_WITH_REJECTIONS,
    ],
    [],
  );

  const otherOptions = useMemo(
    () =>
      [
        [
          OrganizationTransactionStatus.ACTION_NEEDED,
          OrganizationTransactionDetailedStatus.REVIEW_NOW,
        ],
        [
          OrganizationTransactionStatus.ACTION_NEEDED,
          OrganizationTransactionDetailedStatus.READY_FOR_REVIEW,
        ],
        [
          OrganizationTransactionStatus.LIVE,
          OrganizationTransactionDetailedStatus.MEETING_IN_PROGRESS,
        ],
        [OrganizationTransactionStatus.FAILURE, OrganizationTransactionDetailedStatus.EXPIRED],
        [OrganizationTransactionStatus.INACTIVE, OrganizationTransactionDetailedStatus.DRAFT],
        [OrganizationTransactionStatus.INACTIVE, OrganizationTransactionDetailedStatus.RECALLED],
      ] as [OrganizationTransactionStatus, OrganizationTransactionDetailedStatus][],
    [],
  );

  const [showPlaceOrderOnboarding, setShowPlaceOrderOnboarding] = useState(false);

  const {
    clearSelection: clearStatusesSelection,
    removeAll: removeAllStatuses,
    addAll: addAllStatuses,
    toggleSelection: toggleStatusSelection,
  } = useToggleSetWithCallback(selectedStatuses, handleChange, "detailedStatuses");

  const {
    clearSelection: clearOrderProgressSelection,
    removeAll: removeAllOrderProgress,
    addAll: addAllOrderProgress,
    toggleSelection: toggleOrderProgressSelection,
  } = useToggleSetWithCallback(selectedOrderProgressTypes, handleChange, "orderProgress");

  const {
    clearSelection: clearTransactionTypesSelection,
    toggleSelection: toggleTransactionTypeSelection,
    removeAll: removeAllTransactionTypes,
    addAll: addAllTransactionTypes,
  } = useToggleSetWithCallback(selectedTransactionTypes, handleChange, "transactionTypes");

  const setSortValue = useCallback(
    (sortColumn: OrganizationTransactionColumn, sortDirection?: SortDirection) => {
      if (!sortDirection) {
        handleChange({ sortColumn: null, section: undefined, sortDirection });
      } else {
        handleChange({ sortColumn, sortDirection });
      }
    },
    [handleChange],
  );

  const sortBy =
    sortColumn && sortDirection ? { column: sortColumn, direction: sortDirection } : undefined;

  const setSelectedDateConstraint = useCallback<
    ComponentProps<typeof ClosingDateFilter>["setSelectedConstraint"]
  >(
    (dateConstraint) => {
      handleChange({ dateConstraint: dateConstraint || undefined });
    },
    [handleChange],
  );
  const { startDate, endDate } = useDateConstraint(
    selectedDateConstraint,
    setSelectedDateConstraint,
  );

  const variables = getFilterVariables({
    pageIndex,
    activeOrganizationId: activeOrganization.id,
    selectedTransactionTypes,
    startDate,
    endDate,
    query,
    selectedStatuses,
    section,
    orderProgress: selectedOrderProgressTypes,
    subSection,
    sortBy,
  });
  const activeFiltersCount = getActiveFiltersCount({
    ...variables,
    // dont consider OPEN_ORDER status as a filter when in open order section
    detailedStatuses:
      section === TransactionSectionNamespace.OPEN_ORDER ? [] : variables.detailedStatuses,
  });

  const deleteOrganizationTransactionsMutateFn = useMutation(
    DeleteOrganizationTransactionsMutation,
  );

  const {
    data,
    loading: resultsLoading,
    previousData,
    refetch,
  } = useQuery(TitleTransactionsGraph, {
    variables,
  });

  const loading = resultsLoading || cloneTransactionLoading;

  const organization = data
    ? data.node!
    : loading && previousData
      ? previousData.node! // previousData is used to make sure dashboard isn't just wiped clean while querying for new data
      : null;

  if (organization && organization.__typename !== "Organization") {
    throw new Error(`Expected organization, got ${organization.__typename}.`);
  }

  const handleIdentityIssueNotificationClicked = useCallback(
    (transactionId: string) => {
      refetch(); // may not need refetch once txn detail modal removed.
      navigate(transactionDetailsRouteV2({ id: transactionId, queryParams: rawQueryArgs }));
    },
    [refetch, rawQueryArgs],
  );

  const transactions = organization ? organization.transactions : null;
  const totalCount = transactions?.totalCount ?? 0;
  const pageCount = Math.max(Math.ceil(totalCount / PAGE_SIZE), 1);
  const items = useMemo(
    () =>
      transactions
        ? transactions.edges.map(({ node }) => ({
            ...node,
            // the organization column should represent the title agency field on collab txns
            // here, we are assuming if there is no title agency field, then this transaction is non-collab
            organization: node.titleAgency || node.organization,
          }))
        : [],
    [transactions],
  );

  const {
    toggleItem,
    toggleAllItems,
    clearAllItems,
    selectedItemCount,
    selectedItemIdsSet,
    selectedItemIdsArray,
    selectAllCheckboxState,
  } = useBulkActions(items);

  const { preventDelete, showBulkDeleteModal, setShowBulkDeleteModal } = useBulkDelete(
    items,
    selectedItemIdsArray,
  );

  const { preventArchive } = useBulkArchive(items, selectedItemIdsArray);

  const { track: trackNewSetupPageEvent } = useNewSetupPageAnalytics();

  const { canNextPage, canPreviousPage, nextPage, previousPage, startIndex, endIndex } =
    usePagination({
      disabled: loading,
      pageIndex,
      pageCount,
      pageSize: PAGE_SIZE,
      items,
      onPageChange: setPageIndex,
    });

  const getTransactionLinkProps = useCallback(
    (transaction: OrganizationTransaction) => {
      const {
        id,
        userAction,
        documentBundle,
        requiresNsaMeeting,
        contacts,
        type,
        detailedStatus,
        transactionVariant,
      } = transaction;

      const createdAsWetSign = type === MortgageTransactionType.wet_sign;
      const detailsRoute = transactionDetailsRouteV2({ id, queryParams: rawQueryArgs });
      const hybridAccessPortalRoute = `/access/transaction/${id}`;

      // For wet sign transaction, if it's recalled, we don't want title agent to access the hybrid portal
      if (createdAsWetSign) {
        return detailedStatus === OrganizationTransactionDetailedStatus.RECALLED
          ? {
              to: detailsRoute,
              role: "button",
            }
          : {
              to: hybridAccessPortalRoute,
            };
      }

      const meetingToJoin = documentBundle?.meetings.edges.find(
        ({ node: meeting }) => meeting.canJoin,
      );

      if (meetingToJoin) {
        return { to: `/meeting/${meetingToJoin.node.id}` };
      }

      const userIsContactOnTransactionAndHasDocumentAccess = contacts.some(
        (contact) => contact.accessToTransaction && contact.userId === viewer.user!.id,
      );

      const subsidiaryOrganizationIds =
        viewer.user?.organization?.subsidiaryOrganizations.map((org) => org.id) || [];

      const isHybridOutsideCurrentOrganization =
        activeOrganization.id !== transaction.organization.id &&
        !subsidiaryOrganizationIds.includes(transaction.organization.id) &&
        !requiresNsaMeeting &&
        isHybridTransactionType(transaction.type ?? "");

      // If a title agent contact with document access clicks on a hybrid transaction, redirect them to the access portal
      // or for a transaction with the 'wet_sign' transaction type.
      if (isHybridOutsideCurrentOrganization && userIsContactOnTransactionAndHasDocumentAccess) {
        return { to: hybridAccessPortalRoute };
      }

      let coercedTransactionType: "esign" | "proof" | "identify" | "verify" | undefined;
      if (
        transactionVariant === OrganizationTransactionVariant.PROOF ||
        type === MortgageTransactionType.real_estate_proof
      ) {
        // TODO: BIZ-7340 remove real_estate_proof
        coercedTransactionType = signTransactionsEnabled ? "esign" : "proof";
      } else if (
        transactionVariant === OrganizationTransactionVariant.ESIGN ||
        type === MortgageTransactionType.real_estate_esign
      ) {
        coercedTransactionType = "esign";
      } else if (transactionVariant === OrganizationTransactionVariant.IDENTIFY) {
        coercedTransactionType = "identify";
      } else if (transactionVariant === OrganizationTransactionVariant.VERIFY) {
        coercedTransactionType = "verify";
      }

      const route =
        userAction === UserAction.EDIT
          ? transactionEditRoute({
              id,
              type: coercedTransactionType,
              queryParams: rawQueryArgs,
            })
          : userAction === UserAction.PAY
            ? SETTINGS_PATH
            : detailsRoute;

      return {
        to: route,
        role:
          transaction.status === OrganizationTransactionStatus.INACTIVE ||
          transaction.organization.id === organization?.id
            ? undefined
            : "button",
      };
    },
    [rawQueryArgs],
  );

  const handleRowClick = useCallback(
    (transaction: OrganizationTransaction) => {
      const { to } = getTransactionLinkProps(transaction);
      navigate(to);
    },
    [navigate, getTransactionLinkProps],
  );

  const handleCloneClick = (transaction: OrganizationTransaction) => {
    return cloneTransaction(transaction.id);
  };

  const kebabMenuItems: KebabMenuItem<OrganizationTransaction> = [
    {
      label: "View",
      onClick: handleRowClick,
    },
  ];

  if (canUseCloneTransaction) {
    kebabMenuItems.push({
      label: "Clone",
      onClick: handleCloneClick,
      validateItemDisplay: (transaction: OrganizationTransaction) => !transaction.lenderName, // collab txn
    });
  }

  const columns = useMemo(() => {
    // not using data from the active organization because we want to know if the logged in user is part of parent/child or not
    const showLenderColumn = items.some((transaction) => Boolean(transaction.lenderName));
    const showLoanNumberColumn = items.some((transaction) => Boolean(transaction.loanNumber));
    if (section === TransactionSectionNamespace.OPEN_ORDER) {
      return [
        IdentityIssueColumn,
        TransactionTypeColumn(intl, signTransactionsEnabled),
        OrderProgressColumn,
        OrderProgressLastUpdatedColumn,
        FileNumberColumn,
        ...(showLoanNumberColumn ? [LoanNumberColumn] : []),
        PrimaryBorrowerColumn,
        ClosingDateColumn(sortBy, setSortValue),
        AddressColumn,
        USStateColumn,
        ...(showLenderColumn ? [LenderColumn] : []),
        TitleUnderwriterColumn,
        ...(cloneTransactionEnabled // TODO: BIZ-7413, remove ld gate of kebab menu
          ? [KebabMenuColumn<OrganizationTransaction>(intl, kebabMenuItems)]
          : []),
      ];
    }
    return [
      IdentityIssueColumn,
      TransactionTypeColumn(intl, signTransactionsEnabled),
      ...(activeOrganization.proofDefend ||
      isRiskScoresTrialActive(activeOrganization.defendRiskScoresTrial)
        ? [RiskLevelColumn]
        : []),
      StatusColumn,
      FileNumberColumn,
      OrganizationNameColumn,
      PrimaryBorrowerColumn,
      AddressColumn,
      USStateColumn,
      ...(showLenderColumn ? [LenderColumn] : []),
      TitleUnderwriterColumn,
      ClosingDateColumn(sortBy, setSortValue),
      ...(cloneTransactionEnabled // TODO: BIZ-7413, remove ld gate of kebab menu
        ? [KebabMenuColumn<OrganizationTransaction>(intl, kebabMenuItems)]
        : []),
    ];
  }, [activeOrganization.id, items, deserializedArgs]);

  const handleNewTransactionClick = useCallback(() => {
    if (activeOrganization.isSetup.eligibilityComplete) {
      trackNewSetupPageEvent("new-transaction-link", {
        orgType: "title",
      });
      navigate("/transaction/setup/v2");
    } else {
      setShowPlaceOrderOnboarding(true);
    }
  }, [showPlaceOrderUI, viewer]);

  const allTransactionsCount = organization?.allTransactionsCount.totalCount ?? 0;

  const noResults = totalCount === 0;
  const noFilteredResults = allTransactionsCount > 0 && noResults;
  const emptyResults = !loading && noResults;
  const tableSearchResultsId = useId();

  const onDelete = () => {
    return deleteOrganizationTransactionsMutateFn({
      variables: {
        ...variables,
        input: {
          ids: selectedItemIdsArray,
        },
      },
    });
  };

  const bulkUnarchiveButton = (
    <BulkActionUnarchiveButton ids={selectedItemIdsArray} selectedItemCount={selectedItemCount} />
  );

  const bulkArchiveButton = (
    <BulkActionArchiveButton
      ids={selectedItemIdsArray}
      selectedItemCount={selectedItemCount}
      disabled={preventArchive}
    />
  );

  const bulkArchiveDeleteButton = (
    <BulkActionDeleteButton onClick={() => setShowBulkDeleteModal(true)} disabled={preventDelete} />
  );

  const subTab = getSubTab(
    deserializedArgs,
    inProgressStatuses,
    draftStatuses,
    actionNeededStatuses,
    selectedStatuses,
  );

  const transactionDropdown = hasPermissionFor("createOrganizationTransactions") ? (
    <NewRealEstateTransactionMenu
      portal={Apps.TITLE_AGENCY}
      handleRealEstateOption={handleNewTransactionClick}
      showPlaceOrderUI={showPlaceOrderUI}
      orgIsActive={Boolean(organization ? organization.active : true)}
      showVerifyOption={organization?.verifyTransactions}
    />
  ) : null;

  const archivedDashboardTitle = (
    <DashboardTitle
      title={
        <FormattedMessage id="442d2a27-4ac1-4c43-917f-a154e9bcbb91" defaultMessage="Archived" />
      }
      description={
        <FormattedMessage
          id="a4d00737-daad-43be-8f60-1b81dd419da8"
          defaultMessage="This table shows transactions that have been archived by you and your team."
        />
      }
      buttons={transactionDropdown}
    />
  );

  const transactionsDashboardTitle = (
    <>
      {section === TransactionSectionNamespace.OPEN_ORDER ? (
        <FormattedMessage id="e0317f16-fb8e-42f4-87fa-2290a852a82f" defaultMessage="Open orders" />
      ) : (
        <FormattedMessage
          id="7fb36aa7-b4ee-4cd8-a1df-7842a7defa61"
          defaultMessage="All Transactions"
        />
      )}
    </>
  );

  const riskDashboardTitle = (
    <DashboardTitle
      title={
        <FormattedMessage id="5bf31d9a-ecb7-4175-ad30-ebed3dcdfd10" defaultMessage="Risk Alerts" />
      }
    />
  );

  const dashboardTitle =
    section === TransactionSectionNamespace.ARCHIVED ? (
      archivedDashboardTitle
    ) : subSection === TransactionSubsectionNamespace.IDENTITY_RISK ? (
      riskDashboardTitle
    ) : (
      <DashboardTitle title={transactionsDashboardTitle} buttons={transactionDropdown} />
    );

  return (
    <div className="TitleDashboard">
      <IdentityIssueAlerts
        organizationId={activeOrganization.id}
        onAlert={handleIdentityIssueNotificationClicked}
      />
      {showPlaceOrderOnboarding && (
        <PlaceOrderOnboardingContainer
          onEligibilitiesSaved={() => {
            trackNewSetupPageEvent("new-transaction-link", {
              orgType: "title",
            });
            navigate("/transaction/setup/v2");
          }}
          onClose={() => {
            segmentTrack(SEGMENT_EVENTS.TITLE_AGENCY_PLACE_ORDER_ONBOARDING_CLOSE_BUTTON_CLICKED);
            setShowPlaceOrderOnboarding(false);
          }}
          organizationId={activeOrganization.id}
        />
      )}
      <Dashboard>
        <TransactionsSidebar
          organization={organization}
          showDuplicateUI={showDuplicateUI}
          subTab={subTab}
          inProgressStatuses={inProgressStatuses}
          draftStatuses={draftStatuses}
          actionNeededStatuses={actionNeededStatuses}
          section={section}
          transactionsActive={transactionsActive}
        />
        {transactionsActive ? (
          <div className="TitleDashboardContentContainer">
            <DashboardBanner>
              <KbaAlertBanner organizationId={activeOrganization.id} />
            </DashboardBanner>
            <DashboardContent>
              {dashboardTitle}
              <DashboardHeader>
                <DashboardFilters>
                  {section === TransactionSectionNamespace.OPEN_ORDER ? (
                    <>
                      <TableSearch
                        value={textFilterValue}
                        placeholder={intl.formatMessage(MESSAGES.searchPlaceholder)}
                        aria-label={intl.formatMessage(MESSAGES.searchLabel)}
                        onChange={handleTextFilterChange}
                        aria-describedby={noFilteredResults ? tableSearchResultsId : undefined}
                      />
                      <div className="SmallerDropdown">
                        <TransactionTypeFilter
                          selectedTypes={selectedTransactionTypes}
                          clearSelection={clearTransactionTypesSelection}
                          toggleSelection={toggleTransactionTypeSelection}
                          addAll={addAllTransactionTypes}
                          removeAll={removeAllTransactionTypes}
                          disabled={loading}
                        />
                      </div>
                      <OrderProgressFilter
                        disabled={loading}
                        clearSelection={clearOrderProgressSelection}
                        toggleSelection={toggleOrderProgressSelection}
                        selectedOrderProgressTypes={selectedOrderProgressTypes}
                        addAll={addAllOrderProgress}
                        removeAll={removeAllOrderProgress}
                        availableTransactionLabels={organization?.availableTransactionLabels}
                      />
                      <ClosingDateFilterV2
                        disabled={loading}
                        selectedConstraint={selectedDateConstraint}
                        setSelectedConstraint={setSelectedDateConstraint}
                      />
                    </>
                  ) : (
                    <>
                      <TableSearch
                        value={textFilterValue}
                        placeholder={intl.formatMessage(MESSAGES.searchPlaceholder)}
                        aria-label={intl.formatMessage(MESSAGES.searchLabel)}
                        onChange={handleTextFilterChange}
                        aria-describedby={emptyResults ? tableSearchResultsId : undefined}
                      />
                      <div className="SmallerDropdown">
                        {section !== TransactionSectionNamespace.ARCHIVED && (
                          <StatusFilter
                            selectedStatuses={selectedStatuses}
                            inProgressStatuses={inProgressStatuses}
                            completeStatuses={completeStatuses}
                            otherOptions={otherOptions}
                            clearSelection={clearStatusesSelection}
                            removeAll={removeAllStatuses}
                            addAll={addAllStatuses}
                            toggleSelection={toggleStatusSelection}
                            disabled={loading}
                          />
                        )}
                      </div>
                      <div className="SmallerDropdown">
                        <TransactionTypeFilter
                          selectedTypes={selectedTransactionTypes}
                          clearSelection={clearTransactionTypesSelection}
                          toggleSelection={toggleTransactionTypeSelection}
                          addAll={addAllTransactionTypes}
                          removeAll={removeAllTransactionTypes}
                          disabled={loading}
                        />
                      </div>
                      <ClosingDateFilterV2
                        disabled={loading}
                        selectedConstraint={selectedDateConstraint}
                        setSelectedConstraint={setSelectedDateConstraint}
                      />
                    </>
                  )}
                  {activeFiltersCount > 0 && (
                    <ClearAllButton
                      activeFiltersCount={activeFiltersCount}
                      onClick={() => {
                        handleTextFilterChange({ value: "" });
                        handleChange(
                          {
                            section,
                            subSection,
                          },
                          true,
                        );
                      }}
                    />
                  )}
                </DashboardFilters>
              </DashboardHeader>

              {!emptyResults && (
                <DashboardActionBar
                  pagination={
                    <TablePagination
                      canPreviousPage={canPreviousPage}
                      canNextPage={canNextPage}
                      nextPage={nextPage}
                      previousPage={previousPage}
                      startIndex={startIndex}
                      endIndex={endIndex}
                      totalCount={totalCount}
                    />
                  }
                >
                  {selectedItemCount > 0 && (
                    <BulkActionBar itemCount={selectedItemCount}>
                      <BulkActionClearButton onClick={clearAllItems} />
                      <BulkActionSeparator />
                      {section === TransactionSectionNamespace.ARCHIVED
                        ? bulkUnarchiveButton
                        : bulkArchiveButton}
                      {section !== TransactionSectionNamespace.ARCHIVED && bulkArchiveDeleteButton}
                    </BulkActionBar>
                  )}
                </DashboardActionBar>
              )}

              <DashboardBody>
                {emptyResults ? (
                  <EmptyResults
                    tableSearchResultsId={tableSearchResultsId}
                    subTab={subTab}
                    noFilteredResults={noFilteredResults}
                    handleRealEstateOption={handleNewTransactionClick}
                    activeFiltersCount={activeFiltersCount}
                    proofDefendUpsell={proofDefendUpsell}
                  />
                ) : (
                  <BulkTable
                    toggleItem={toggleItem}
                    toggleAllItems={toggleAllItems}
                    selectedItemIds={selectedItemIdsSet}
                    selectAllCheckboxState={selectAllCheckboxState}
                    data={items}
                    columns={columns}
                    loading={loading}
                    caption={intl.formatMessage(MESSAGES.caption)}
                    rowInteraction={{
                      onClick: handleRowClick,
                      getLinkProps: getTransactionLinkProps,
                    }}
                    totalItems={totalCount}
                    getAutomationId={(transaction: OrganizationTransaction) =>
                      `transaction-row-${transaction.id}`
                    }
                    stickyHotDogMenu={cloneTransactionEnabled} // TODO: BIZ-7413, remove ld gate of kebab menu
                  />
                )}
              </DashboardBody>
            </DashboardContent>
          </div>
        ) : (
          <Outlet />
        )}
      </Dashboard>

      {showBulkDeleteModal && (
        <BulkTransactionDeleteModal
          onDelete={onDelete}
          onClose={() => setShowBulkDeleteModal(false)}
          totalTransactions={selectedItemIdsArray.length}
        />
      )}

      {transactionsActive && <Outlet />}
    </div>
  );
}

function TitleTransactionsV2Wrapper() {
  const [activeOrganizationId] = useActiveOrganization();
  const { data, loading } = useQuery(TitleDashboardGraph, {
    variables: { organizationId: activeOrganizationId! },
  });

  if (loading) {
    return <LoadingIndicator />;
  }

  const viewer = data!.viewer;
  const organization = data!.node!;
  if (organization.__typename !== "Organization") {
    throw new Error(`Expected organization, got ${organization.__typename}.`);
  }

  return <TitleTransactionsV2 viewer={viewer} activeOrganization={organization} />;
}

export default TitleTransactionsV2Wrapper;
